import React, { useRef, useState, useEffect } from "react";
import { BrowserMultiFormatReader, BarcodeFormat } from "@zxing/library";

const BarcodeScanner = ({ onSuccess, setShowBarcodeScanner }) => {
  const videoRef = useRef(null);
  const [error, setError] = useState(null);
  const [devices, setDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [scanned, setScanned] = useState(false);

  useEffect(() => {
    const codeReader = new BrowserMultiFormatReader();

    const initDevices = async () => {
      try {
        const videoDevices = await codeReader.listVideoInputDevices();

        if (videoDevices.length > 0) {
          setDevices(videoDevices);
          setSelectedDeviceId(videoDevices[0].deviceId);
        } else {
          setError("Keine Kamera gefunden");
        }
      } catch (err) {
        setError("Fehler beim Abrufen der Kameras: " + err.message);
      }
    };

    const requestCameraAccess = async () => {
      try {
        // Überprüfen, ob der Browser die Methode unterstützt
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          // Berechtigung anfragen
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          // Stream auf das Videoelement anwenden
          videoRef.current.srcObject = stream;

          initDevices();
        } else {
          setError("Kamera-Zugriff wird von diesem Browser nicht unterstützt.");
        }
      } catch (err) {
        setError("Fehler beim Zugriff auf die Kamera: " + err.message);
      }
    };

    requestCameraAccess();

    return () => {
      codeReader.reset();
    };
  }, []);

  useEffect(() => {
    const codeReader = new BrowserMultiFormatReader();

    const startScanner = async () => {
      if (selectedDeviceId) {
        try {
          await codeReader.decodeFromVideoDevice(
            selectedDeviceId,
            videoRef.current,
            (result, err) => {
              if (result) {
                onSuccess(result.text);
                setScanned(true);
                setTimeout(() => {
                  setScanned(false);
                  setShowBarcodeScanner(false);
                }, 500);
              }
            },
            {
              formats: [BarcodeFormat.EAN_13],
            }
          );
        } catch (err) {
          setError("Fehler beim Zugriff auf die Kamera: " + err.message);
        }
      }
    };

    /*const requestCameraAccess = async () => {
      try {
        // Überprüfen, ob der Browser die Methode unterstützt
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          // Berechtigung anfragen
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          // Stream auf das Videoelement anwenden
          videoRef.current.srcObject = stream;
          startScanner();
        } else {
          setError("Kamera-Zugriff wird von diesem Browser nicht unterstützt.");
        }
      } catch (err) {
        setError("Fehler beim Zugriff auf die Kamera: " + err.message);
      }
    };

    requestCameraAccess();*/

    if (devices.length >= 1) {
      startScanner();
    }

    return () => {
      codeReader.reset();
    };
  }, [selectedDeviceId, onSuccess]);

  return (
    <div style={{ textAlign: "center" }}>
      <p>
        <select
          value={selectedDeviceId}
          onChange={(e) => setSelectedDeviceId(e.target.value)}
        >
          {devices.map((device, index) => (
            <option key={index} value={device.deviceId}>
              {device.label || `Kamera ${index + 1}`}
            </option>
          ))}
        </select>
      </p>
      {!error && (
        <div
          style={{
            position: "relative",
            display: "inline-block",
            margin: "10px 0",
          }}
        >
          <video
            ref={videoRef}
            style={{ width: "100%", height: "auto", maxWidth: "300px" }}
            autoPlay
          />
          <div className={`viewfinder ${scanned ? "scanned" : ""}`}>
            <div className="corner top-left"></div>
            <div className="corner top-right"></div>
            <div className="corner bottom-left"></div>
            <div className="corner bottom-right"></div>
          </div>
        </div>
      )}
      {error && <p>{error}</p>}
    </div>
  );
};

export default BarcodeScanner;
